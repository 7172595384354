const Decimal = require("decimal.js");

export function calculateTotalOdds(priceList) {
  let multiplicationFactor = new Decimal(1);

  for (const price of priceList) {
    multiplicationFactor = multiplicationFactor.times(new Decimal(price));
  }

  return Number(multiplicationFactor.toDecimalPlaces(3, Decimal.ROUND_HALF_UP));
}

export function calculatePotentialWin(debit, priceList) {
  let multiplicationFactor = new Decimal(1);
  let snrMultiplicationFactor = new Decimal(1);

  for (const price of priceList) {
    // factor in any snr % of the bet
    const effectivePrice = new Decimal(price);
    const effectiveSNRPrice = new Decimal(price - 1);
    multiplicationFactor = multiplicationFactor.times(effectivePrice);
    snrMultiplicationFactor = snrMultiplicationFactor.times(effectiveSNRPrice);
  }

  multiplicationFactor = multiplicationFactor.toDecimalPlaces(3, Decimal.ROUND_HALF_UP);
  snrMultiplicationFactor = snrMultiplicationFactor.toDecimalPlaces(3, Decimal.ROUND_HALF_UP);

  const regularPotentialWin = multiplicationFactor.times(new Decimal(debit));

  let potentialWin = regularPotentialWin;
  potentialWin = potentialWin.toDecimalPlaces(2, Decimal.ROUND_HALF_UP);

  return Number(potentialWin);
}
