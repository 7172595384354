import { AbstractPriceFormat } from "./abstractPriceFormat";

export class USPriceFormat extends AbstractPriceFormat {
  constructor() {
    super();
    this.CHAR_NOT_ALLOWED = "Character Not Allowed";
    this.INVALID_PRICE_VALUE = "Invalid Price";
    this.MINUS_SIGN = "-";
    this.PLUS_SIGN = "+";
  }

  format(price) {
    if (price === 0) {
      return this.NO_PRICE;
    }

    return this.convertDecimalToUS(price);
  }

  parse(price) {
    if (price.includes("/")) {
      throw new Error(`${this.CHAR_NOT_ALLOWED}: /`);
    }
    if (price.includes(".")) {
      throw new Error(`${this.CHAR_NOT_ALLOWED}: .`);
    }

    if (price === null || price === this.NO_PRICE || price === this.MINUS_SIGN || price === this.PLUS_SIGN) {
      return 0;
    }

    if (!this.isNumeric(price, null)) {
      throw new Error(this.INVALID_PRICE_VALUE);
    }

    return this.convertUSToDecimal(price);
  }

  convertUSToDecimal(price) {
    let usPrice;
    try {
      usPrice = parseFloat(price);
    } catch (error) {
      throw new Error(`Parse error: ${error.message}`);
    }

    return this.convertUSToDecimalValue(usPrice);
  }

  convertUSToDecimalValue(usPrice) {
    let decimalPrice;

    if (usPrice > 0) {
      decimalPrice = usPrice / 100 + 1;
    } else if (usPrice < 0) {
      decimalPrice = 100 / Math.abs(usPrice) + 1;
    } else {
      decimalPrice = 0;
    }

    return decimalPrice;
  }

  convertDecimalToUS(decimalPrice) {
    let usPrice;
    let stringPrice;

    if (decimalPrice >= 2) {
      usPrice = (decimalPrice - 1) * 100;
      stringPrice = `+${Math.round(usPrice)}`;
    } else if (decimalPrice > 0 && decimalPrice !== 1.0) {
      usPrice = -100 / (decimalPrice - 1.0);
      stringPrice = Math.round(usPrice).toString();
    } else {
      stringPrice = "Infinity";
    }

    return stringPrice;
  }

  isValidPriceCharacter(c) {
    return c === "+" || c === "-" || (c >= "0" && c <= "9");
  }

  isNumeric(strTest, decimalSep) {
    // US format doesn't use decimal separator, so we ignore decimalSep
    return /^[+-]?\d+$/.test(strTest.trim());
  }
}
