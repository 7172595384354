import isEmpty from "lodash.isempty";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { getAuthLanguage } from "../redux/reselect/auth-selector";
import { getBetTypeSelector } from "../redux/reselect/bettype-selector";
import { getBetTypes } from "../redux/slices/betTypeSlice";

export const useGetBetTypes = (dispatch) => {
  const language = useSelector(getAuthLanguage);
  const betTypes = useSelector(getBetTypeSelector);
  const betTypeTranslationLanguage = useSelector((state) => state.betType.language);

  useEffect(() => {
    if (isEmpty(betTypes) || language !== betTypeTranslationLanguage) {
      dispatch(getBetTypes());
    }
  }, [dispatch, language]);

  return betTypes;
};
