export function sanitiseLanguage(rawLanguage) {
  const language = rawLanguage.split("-")[0].split("_")[0].toLowerCase();

  switch (language) {
    case "de":
    case "en":
    case "es":
    case "fr":
    case "id":
    case "ja":
    case "ko":
    case "ms":
    case "pt":
    case "ru":
    case "th":
    case "vi":
    case "zh":
    case "hi":
    case "te":
    case "km":
      return language;

    default:
      return "en";
  }
}
