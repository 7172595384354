export function createDataHash(data) {
  // Create an object with all the data we want to hash
  const hashData = {
    ...data,
  };

  // Remove fromTimestamp from the hash data
  delete hashData.fromTimestamp;

  const sortedKeys = Object.keys(hashData).sort();
  const hashString = sortedKeys
    .filter((key) => hashData[key] != null) // Exclude null and undefined
    .map((key) => `${key}:${hashData[key]}`)
    .join("|");

  // Use a simple hash function
  let hash = 0;
  for (let i = 0; i < hashString.length; i++) {
    const char = hashString.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32-bit integer
  }

  // Convert to a positive 8-character hex string
  return (hash >>> 0).toString(16).padStart(8, "0");
}
