import { useDispatch } from "react-redux";
import { useCurrentBreakpointName } from "react-socks";

import LoadingLogo from "../components/LoadingLogo";
import MaintenancePage from "../components/MaintenancePage";
import {
  useBetPointLineId,
  useBetPointOn,
  useBetPointOriginId,
  useConfigIsInitialized,
  useDesktopLineId,
  useDesktopOriginId,
  useMobileLineId,
  useMobileOriginId,
  useSlipstreamLineId,
  useSlipstreamOn,
  useSlipstreamOriginId,
} from "../utils/AppUtils";

import { useQueryParams } from "hooks/auth-hooks";
import { useCmsConfig } from "hooks/cms-hooks";

const useOriginAndLineIds = (isMobileDevice, isSlipstream, isBetPoint) => {
  const initialized = useConfigIsInitialized();

  const desktopOriginId = useDesktopOriginId();
  const mobileOriginId = useMobileOriginId();
  const desktopLineId = useDesktopLineId();
  const mobileLineId = useMobileLineId();
  const slipstreamOriginId = useSlipstreamOriginId();
  const slipstreamLineId = useSlipstreamLineId();
  const betpointOriginId = useBetPointOriginId();
  const betpointLineId = useBetPointLineId();

  if (isSlipstream) {
    return { initialized, lineId: slipstreamLineId, originId: slipstreamOriginId };
  }
  if (isBetPoint) {
    return { initialized, lineId: betpointLineId, originId: betpointOriginId };
  }
  if (isMobileDevice) {
    return { initialized, lineId: mobileLineId, originId: mobileOriginId };
  }

  return { initialized, lineId: desktopLineId, originId: desktopOriginId };
};

const withCms = (Component) => (props) => {
  // Obtain the CMS configuration
  const dispatch = useDispatch();

  // identify the device type based on media queries... DESKTOP vs MOBILE
  const breakpoint = useCurrentBreakpointName();
  const isMobileDevice = breakpoint === "xsmall" || breakpoint === "small";

  const isSlipstream = useSlipstreamOn();
  const isBetPointOnAux = useBetPointOn();
  const isBetPoint = isBetPointOnAux && !isSlipstream; // do not allow both to be on at the same time

  // Load `CMS` data base on `isMobileDevice`.
  const { lineId, originId } = useOriginAndLineIds(isMobileDevice, isSlipstream, isBetPoint);
  const { cmsConfig, cmsConfigError, cmsConfigErrorCode } = useCmsConfig(dispatch, originId, lineId);

  // Save auth data base on query params.
  useQueryParams(isMobileDevice, dispatch);

  if (!cmsConfig) {
    // if there is an error, and not because the request was cancelled (which can happen in the initial process), send the user to the maintenance page
    if (cmsConfigError && cmsConfigErrorCode !== "ERR_CANCELED") {
      console.log("cmsConfig", cmsConfig);
      console.log("cmsConfigError", cmsConfigError);
      console.log("cmsConfigErrorCode", cmsConfigErrorCode);

      return <MaintenancePage />;
    }

    // If we have no config and no error (meaning we must be loading it or about to), show the loading logo
    return <LoadingLogo />;
  }

  return (
    <Component
      isBetPoint={isBetPoint}
      isMobileDevice={isMobileDevice && !isSlipstream && !isBetPoint}
      isSlipstream={isSlipstream}
      {...props}
    />
  );
};

export default withCms;
