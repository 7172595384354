import { ChinesePriceFormat } from "./chinesePriceFormat";
import { EuroPriceFormat } from "./euroPriceFormat";
import { IndonesianPriceFormat } from "./indonesianPriceFormat";
import { MalayPriceFormat } from "./malayPriceFormat";
import { UKPriceFormat } from "./ukPriceFormat";
import { USPriceFormat } from "./usPriceFormat";

export class PriceFormatter {
  constructor() {
    this.formatters = {
      CHINESE: new ChinesePriceFormat(),
      EURO: new EuroPriceFormat(),
      INDO: new IndonesianPriceFormat(),
      MALAY: new MalayPriceFormat(),
      UK: new UKPriceFormat(),
      US: new USPriceFormat(),
    };
  }

  format(decimalPrice, formatType) {
    const formatter = this.formatters[formatType];
    if (!formatter) {
      throw new Error("Invalid format type");
    }

    return formatter.format(decimalPrice);
  }

  parse(formattedPrice, formatType) {
    const formatter = this.formatters[formatType];
    if (!formatter) {
      throw new Error("Invalid format type");
    }

    return formatter.parse(formattedPrice);
  }
}
