import { AbstractPriceFormat } from "./abstractPriceFormat";

export class UKPriceFormat extends AbstractPriceFormat {
  constructor() {
    super();
    this.INVALID_PRICE_VALUE = "Invalid Price {0}";
    this.NUMERATOR_DENOMINATOR_NOT_ZERO = "Numerator and/or denominator cannot be zero";
    this.dividerText = "/";
    this.conversionMap = this.conversionMap = new Map([
      [1.001, "1/1000"],
      [1.002, "1/500"],
      [1.005, "1/200"],
      [1.01, "1/100"],
      [1.012, "1/80"],
      [1.015, "1/66"],
      [1.02, "1/50"],
      [1.025, "1/40"],
      [1.03, "1/33"],
      [1.035, "1/28"],
      [1.04, "1/25"],
      [1.05, "1/20"],
      [1.055, "1/18"],
      [1.06, "1/16"],
      [1.07, "1/14"],
      [1.08, "1/12"],
      [1.09, "1/11"],
      [1.1, "1/10"],
      [1.105, "2/19"],
      [1.11, "1/9"],
      [1.117, "2/17"],
      [1.12, "1/8"],
      [1.13, "2/15"],
      [1.14, "1/7"],
      [1.15, "2/13"],
      [1.16, "1/6"],
      [1.18, "2/11"],
      [1.2, "1/5"],
      [1.22, "2/9"],
      [1.24, "1/4"],
      [1.28, "2/7"],
      [1.3, "3/10"],
      [1.33, "1/3"],
      [1.35, "4/11"],
      [1.4, "2/5"],
      [1.44, "4/9"],
      [1.47, "40/85"],
      [1.5, "1/2"],
      [1.53, "8/15"],
      [1.55, "4/7"],
      [1.6, "3/5"],
      [1.61, "8/13"],
      [1.65, "4/6"],
      [1.7, "7/10"],
      [1.71, "8/11"],
      [1.75, "3/4"],
      [1.8, "4/5"],
      [1.83, "5/6"],
      [1.85, "17/20"],
      [1.9, "9/10"],
      [1.905, "10/11"],
      [1.95, "19/20"],
      [1.952, "20/21"],
      [2, "1/1"],
      [2.05, "21/20"],
      [2.1, "11/10"],
      [2.15, "23/20"],
      [2.2, "6/5"],
      [2.25, "5/4"],
      [2.3, "13/10"],
      [2.35, "11/8"],
      [2.4, "7/5"],
      [2.45, "29/20"],
      [2.5, "6/4"],
      [2.55, "31/20"],
      [2.6, "8/5"],
      [2.62, "13/8"],
      [2.7, "17/10"],
      [2.75, "7/4"],
      [2.8, "9/5"],
      [2.85, "37/20"],
      [2.87, "15/8"],
      [2.9, "19/10"],
      [2.95, "39/20"],
      [3, "2/1"],
      [3.1, "21/10"],
      [3.125, "85/40"],
      [3.2, "11/5"],
      [3.25, "9/4"],
      [3.3, "23/10"],
      [3.4, "12/5"],
      [3.5, "5/2"],
      [3.6, "13/5"],
      [3.75, "11/4"],
      [3.8, "14/5"],
      [4, "3/1"],
      [4.2, "16/5"],
      [4.25, "13/4"],
      [4.3, "10/3"],
      [4.4, "17/5"],
      [4.5, "7/2"],
      [4.6, "18/5"],
      [4.75, "15/4"],
      [4.8, "19/5"],
      [5, "4/1"],
      [5.25, "17/4"],
      [5.5, "9/2"],
      [5.75, "19/4"],
      [6, "5/1"],
      [6.25, "21/4"],
      [6.5, "11/2"],
      [6.55, "100/18"],
      [6.75, "23/4"],
      [7, "6/1"],
      [7.25, "25/4"],
      [7.5, "13/2"],
      [7.6, "100/15"],
      [7.75, "27/4"],
      [8, "7/1"],
      [8.15, "100/14"],
      [8.25, "29/4"],
      [8.5, "15/2"],
      [8.7, "100/13"],
      [8.75, "31/4"],
      [9, "8/1"],
      [9.25, "33/4"],
      [9.3, "100/12"],
      [9.5, "17/2"],
      [9.75, "35/4"],
      [10, "9/1"],
      [10.1, "100/11"],
      [10.25, "37/4"],
      [10.5, "19/2"],
      [10.75, "39/4"],
      [11, "10/1"],
      [12, "11/1"],
      [12.1, "100/9"],
      [13, "12/1"],
      [13.5, "100/8"],
      [14, "13/1"],
      [15, "14/1"],
      [15.2, "100/7"],
      [16, "15/1"],
      [17, "16/1"],
      [17.6, "100/6"],
      [18, "17/1"],
      [19, "18/1"],
      [19.1, "200/11"],
      [20, "19/1"],
    ]);
  }

  format(price) {
    if (price === 0) {
      return this.NO_PRICE;
    }

    return this.convertDecimalToFraction(price);
  }

  parse(price) {
    try {
      if (price === null || price === this.NO_PRICE) {
        return 0.0;
      }

      // We do not allow '+' '-' or '.' in the price
      if (price.includes(".") || price.includes("+") || price.includes("-")) {
        throw new Error(this.INVALID_PRICE_VALUE.replace("{0}", price));
      }

      // Check the price doesn't start or end with a slash
      if (price.startsWith("/") || price.endsWith("/")) {
        throw new Error(this.INVALID_PRICE_VALUE.replace("{0}", price));
      }

      // If there is no '/' then we default the price to X/1 e.g. '4' becomes '4/1' to speed up pricing
      if (!price.includes("/")) {
        let result = parseFloat(price);
        if (result > 0.0) {
          // Add 1 to get the correct decimal price
          result += 1.0;
        }

        return result;
      }

      // Pull out the numerator and denominator and store them
      const [numerator, denominator] = price.split(this.dividerText).map(Number);

      return this.convertFractionToDecimal(numerator, denominator);
    } catch (e) {
      throw new Error(this.INVALID_PRICE_VALUE.replace("{0}", price));
    }
  }

  convertDecimalToFraction(decimal) {
    if (decimal >= 21) {
      return this.convertDecimalToFractionMathematically(Math.round(decimal));
    }

    // Find the closest mapping
    const entries = Array.from(this.conversionMap.entries());
    const closest = entries.reduce((prev, curr) =>
      Math.abs(curr[0] - decimal) < Math.abs(prev[0] - decimal) ? curr : prev,
    );

    return closest[1];
  }

  convertFractionToDecimal(numerator, denominator) {
    if (numerator === 0 || denominator === 0) {
      throw new Error(this.NUMERATOR_DENOMINATOR_NOT_ZERO);
    }

    return 1 + numerator / denominator;
  }

  convertDecimalToFractionMathematically(decimal) {
    const NUMFRACS = 30;
    const numerator = new Array(NUMFRACS);
    const denominator = new Array(NUMFRACS);
    const d = Math.abs(decimal - 1);
    let d2 = d;
    let d4 = -1;

    const maxNumerator = this.getMaxNumerator(d.toString());

    numerator[0] = 0;
    denominator[0] = 1;
    numerator[1] = 1;
    denominator[1] = 0;

    let result = "";
    for (let i = 2; i < NUMFRACS; i++) {
      const L2 = Math.round(d2);
      numerator[i] = L2 * numerator[i - 1] + numerator[i - 2];
      denominator[i] = L2 * denominator[i - 1] + denominator[i - 2];

      const d3 = numerator[i] / denominator[i];
      if (d3 === d4) break;

      if (Math.abs(numerator[i]) > maxNumerator) break;

      if (Math.abs(denominator[i]) <= 100) {
        result = `${Math.abs(numerator[i])}/${Math.abs(denominator[i])}`;
      }

      if (d3 === d) break;
      d4 = d3;

      d2 = 1 / (d2 - L2);
    }

    return result;
  }

  getMaxNumerator(f) {
    const f2 = f.split("E")[0];
    const digits = f2.replace(".", "");
    const L = BigInt(digits);
    const numDigits = L.toString().length;
    const L2 = BigInt(Math.floor(parseFloat(f)));
    const numIntDigits = L2 === 0n ? 0 : L2.toString().length;
    const numDigitsPastDecimal = numDigits - numIntDigits;

    let result = L;
    for (let i = numDigitsPastDecimal; i > 0 && result % 2n === 0n; i--) {
      result /= 2n;
    }
    for (let i = numDigitsPastDecimal; i > 0 && result % 5n === 0n; i--) {
      result /= 5n;
    }

    return result;
  }

  isValidPriceCharacter(c) {
    return c === "/" || (c >= "0" && c <= "9");
  }
}
