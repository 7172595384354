import { AbstractPriceFormat } from "./abstractPriceFormat";

export class MalayPriceFormat extends AbstractPriceFormat {
  constructor() {
    super();
    this.numberFormat = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 3,
      useGrouping: false,
    });
  }

  format(decimalPrice) {
    const price = this.decimalToMalay(decimalPrice);

    return price === 0 ? this.NO_PRICE : this.numberFormat.format(price);
  }

  parse(price) {
    if (price === null || price === this.NO_PRICE) {
      return 0.0;
    }

    try {
      const malayPrice = parseFloat(price);
      if (!this.isValid(malayPrice)) {
        throw new Error("Not a valid malay price");
      }

      return this.malayToDecimal(malayPrice);
    } catch (error) {
      throw new Error(`Parse error: ${error.message}`);
    }
  }

  malayToDecimal(malayPrice) {
    let decimalPrice = 0;

    if (malayPrice < 0) {
      decimalPrice = 1 / Math.abs(malayPrice) + 1;
    } else {
      decimalPrice = malayPrice + 1;
    }

    return decimalPrice;
  }

  decimalToMalay(decimalPrice) {
    decimalPrice--; // Remove the stake

    if (decimalPrice >= 1) {
      return 1 / -decimalPrice;
    }

    return decimalPrice;
  }

  isValid(malayPrice) {
    return malayPrice < 1 && malayPrice >= -1;
  }

  isValidPriceCharacter(c) {
    return c === "." || (c >= "0" && c <= "9") || c === "-";
  }
}
