export function getDefault12BetCurrencyCode(language) {
  switch (language) {
    case "en":
    case "hi":
    case "te":
      return "INR";
    case "zh":
      return "CNY";
    case "ko":
      return "KRW";
    case "th":
      return "THB";
    case "km":
      return "KHR";
    case "id":
      return "IDR";
    case "vi":
      return "VND";
    case "ms":
      return "MYR";
    case "bn":
      return "BDT";

    default:
      return "INR";
  }
}

export function getSanitisedPriceFormat(priceFormat) {
  if (["EURO", "UK", "US", "MALAY", "INDO", "CHINESE"].includes(priceFormat)) return priceFormat;

  return undefined;
}

export function getDefault12BetView(language, isMobileDevice) {
  if (isMobileDevice) {
    switch (language) {
      case "en":
      case "hi":
      case "te":
      case "bn":
        return "VANILLA";
      case "ko":
        return "SLIM";
      case "zh":
      case "th":
      case "km":
      case "id":
      case "vi":
      case "ms":
        return "ASIAN";

      default:
        return "ASIAN";
    }
  } else {
    switch (language) {
      case "en":
      case "hi":
      case "te":
      case "bn":
        return "EUROPEAN";
      case "ko":
        return "SLIM";
      case "zh":
      case "th":
      case "km":
      case "id":
      case "vi":
      case "ms":
        return "ASIAN";

      default:
        return "ASIAN";
    }
  }
}
