import { AbstractPriceFormat } from "./abstractPriceFormat";

export class IndonesianPriceFormat extends AbstractPriceFormat {
  constructor() {
    super();
    this.numberFormat = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 3,
      useGrouping: false,
    });
  }

  format(decimalPrice) {
    const price = this.decimalToIndonesian(decimalPrice);

    return price === 0 ? this.NO_PRICE : this.numberFormat.format(price);
  }

  parse(price) {
    if (price === null || price === this.NO_PRICE) {
      return 0.0;
    }

    try {
      const indonesianPrice = parseFloat(price);
      if (!this.isValid(indonesianPrice)) {
        throw new Error("Not a valid Indonesian price");
      }

      return this.indonesianToDecimal(indonesianPrice);
    } catch (error) {
      throw new Error(`Parse error: ${error.message}`);
    }
  }

  indonesianToDecimal(indonesianPrice) {
    let decimalPrice = 0;

    if (indonesianPrice >= 1) {
      decimalPrice = indonesianPrice + 1;
    } else {
      decimalPrice = 1 / Math.abs(indonesianPrice) + 1;
    }

    return decimalPrice;
  }

  decimalToIndonesian(decimalPrice) {
    decimalPrice--; // Remove the stake

    if (decimalPrice >= 1) {
      return decimalPrice;
    }

    return -(1 / decimalPrice);
  }

  isValid(indonesianPrice) {
    return !(indonesianPrice < 1 && indonesianPrice >= -1);
  }

  isValidPriceCharacter(c) {
    return c === "." || (c >= "0" && c <= "9") || c === "-";
  }
}
