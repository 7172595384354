export class AbstractPriceFormat {
  constructor() {
    this.NO_PRICE = "";
  }

  isNumeric(strTest, decimalSep) {
    const match = /[+-]?(\d+[.,])?\d+([.,]\d*)?/;

    return match.test(strTest.replace(/\s/g, ""));
  }
}
