import { AbstractPriceFormat } from "./abstractPriceFormat";

export class ChinesePriceFormat extends AbstractPriceFormat {
  constructor() {
    super();
    this.numberFormat = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 3,
      useGrouping: false,
    });
  }

  format(decimalPrice) {
    const price = this.decimalToChinese(decimalPrice);

    return price === 0 ? this.NO_PRICE : this.numberFormat.format(price);
  }

  parse(price) {
    if (price === null || price === this.NO_PRICE) {
      return 0.0;
    }

    try {
      const chinesePrice = parseFloat(price);
      if (!this.isValid(chinesePrice)) {
        throw new Error("Not a valid Chinese price");
      }

      return this.chineseToDecimal(chinesePrice);
    } catch (error) {
      throw new Error(`Parse error: ${error.message}`);
    }
  }

  chineseToDecimal(chinesePrice) {
    return chinesePrice + 1;
  }

  decimalToChinese(decimalPrice) {
    return decimalPrice - 1;
  }

  isValid(price) {
    return price > 0;
  }

  isValidPriceCharacter(c) {
    return c === "." || (c >= "0" && c <= "9");
  }
}
