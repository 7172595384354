import { createContext, useEffect, useState } from "react";

const ConfigSettingsContext = createContext(null);

export { ConfigSettingsContext };

// The purpose of this function is to load the environment settings from the filesystem config file

// eslint-disable-next-line react/prop-types
export default ({ children }) => {
  const [config, setConfig] = useState({});
  useEffect(() => {
    fetch(`/config.env?${process.env.REACT_APP_I18NEXT_TAG ?? "1.3.7"}`) // The query string is used to force a new version of the file - we use the same version as the I18 next, which is updated by build
      .then((response) => response.text())
      .then((text) => {
        const lines = text.split("\n");
        const config = lines.reduce((acc, line) => {
          // Check if line is not empty, does not start with a comment, and contains '='
          if (line && !line.startsWith("#") && line.includes("=")) {
            const [key, ...valueParts] = line.split("="); // Split the line by the first '='
            const value = valueParts.join("="); // Join the remaining parts to form the value (in case the value contains '=' too)
            if (key && value) {
              acc[key.trim()] = value.trim();
            }
          }

          return acc;
        }, {});

        setConfig(config);
      })
      .catch((error) => console.error("Failed to fetch environment file:", error));
  }, []); // do this once and only once!

  return <ConfigSettingsContext.Provider value={config}>{children}</ConfigSettingsContext.Provider>;
};
