import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as axiosOriginal from "axios";

import createAxiosInstance from "../async/axios";
import { getRequestParams } from "../async/get-fetch-params";

const isLocalhost = () =>
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname === "";

const getExternalIP = async () => {
  try {
    const response = await axiosOriginal.default.get("https://api.ipify.org?format=json");

    return response.data.ip;
  } catch (error) {
    console.error("Error fetching external IP:", error);

    return null;
  }
};

export const getInitialState = () => ({
  activeStreamId: null,
  error: null,
  loading: false,
  streamHash: {},
});

export const getAVLiveStreamByEvent = createAsyncThunk(
  "avLive/loadAVLiveStreamURL",
  async ({ eventId, simulateXForwardedFor }, thunkAPI) => {
    try {
      const { accountId, authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());
      const axios = createAxiosInstance(thunkAPI.dispatch, {
        authToken,
        language,
      });

      if (isLocalhost()) {
        const externalIP = await getExternalIP();
        if (externalIP) {
          axios.defaults.headers["x-forwarded-for"] = externalIP;
          console.log(`Running in dev mode. Set X-Forwarded-For header to ${externalIP}`);
        }
      }

      const result = await axios.get(
        `/player/acc/${accountId}/avlive/hls?eventId=${eventId}&originId=${originId}&lineId=${lineId}`,
      );

      return {
        eventId,
        streamUrl: result.data.streamUrl,
      };
    } catch (err) {
      const customError = {
        message: err.response?.headers["x-information"] || "Unable to obtain live video stream details", // serializable (err.response.data)
        name: "Video Live Stram Fetch Error",
        status: err.response?.statusText,
      };
      throw customError;
    }
  },
);

const avLiveSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getAVLiveStreamByEvent.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getAVLiveStreamByEvent.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(getAVLiveStreamByEvent.fulfilled, (state, action) => {
        const eventId = action.payload.eventId;

        state.streamHash[eventId] = action.payload.streamUrl;
        state.loading = false;
        state.error = null;
      });
  },
  initialState: getInitialState(),
  name: "avlive",
  reducers: {
    clearStream(state, action) {
      const eventId = action.payload.eventId;
      delete state.streamHash[eventId];
    },
    removeActiveLiveStream(state, _) {
      state.activeStreamId = null;
    },
    setActiveLiveStream(state, action) {
      state.activeStreamId = action.payload.eventId;
    },
  },
});
const { actions, reducer } = avLiveSlice;
export const { clearStream, removeActiveLiveStream, setActiveLiveStream } = actions;
export default reducer;
