export const APPLICATION_TYPE_MOBILE_CITY = "CITY";
export const APPLICATION_TYPE_MOBILE_SLIM = "SLIM";
export const APPLICATION_TYPE_MOBILE_VANILLA = "VANILLA";
export const APPLICATION_TYPE_MOBILE_ASIAN = "ASIAN";

export const APPLICATION_TYPE_CITY_DESKTOP = "CITY";
export const APPLICATION_TYPE_OB_DESKTOP = "OB";
export const APPLICATION_TYPE_EUROPEAN_DESKTOP = "EUROPEAN";
export const APPLICATION_TYPE_CONTINENTAL_DESKTOP = "CONTINENTAL";
export const APPLICATION_TYPE_AMERICAN_DESKTOP = "AMERICAN";
export const APPLICATION_TYPE_ASIAN_DESKTOP = "ASIAN";
export const APPLICATION_TYPE_COMPACT_DESKTOP = "COMPACT";
export const APPLICATION_TYPE_SLIM_DESKTOP = "SLIM";
export const APPLICATION_TYPE_SUMMIT_DESKTOP = "SUMMIT";
