import dayjs from "dayjs";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import { getAuthAccountId, getAuthIFrameQueryParamsProcessed, getAuthLoggedIn } from "../redux/reselect/auth-selector";
import { getCmsConfigBrandDetails } from "../redux/reselect/cms-selector";
import { loadSingleWalletBalance } from "../redux/slices/balanceSlice";
import { ConfigSettingsContext } from "settings/ConfigSettings";

require("dayjs/locale/de");
require("dayjs/locale/es");
require("dayjs/locale/fr");
require("dayjs/locale/id");
require("dayjs/locale/hi");
require("dayjs/locale/ja");
require("dayjs/locale/km");
require("dayjs/locale/ko");
require("dayjs/locale/ms");
require("dayjs/locale/pt");
require("dayjs/locale/ru");
require("dayjs/locale/th");
require("dayjs/locale/te");
require("dayjs/locale/vi");
require("dayjs/locale/zh");

// https://day.js.org/docs/en/parse/string-format
const customParseFormat = require("dayjs/plugin/customParseFormat");

dayjs.extend(customParseFormat);

export const useConfig = () => {
  const config = useContext(ConfigSettingsContext);

  const variables = {
    betpointOn: config?.REACT_APP_BETPOINT_ON === "true",
    betpointOriginId: config?.REACT_APP_MOBILE_ORIGIN_ID,
    desktopOriginId: config?.REACT_APP_DESKTOP_ORIGIN_ID,
    googleAnalyticsMeasurementId: config?.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
    googleAnalyticsOn: config?.REACT_APP_GOOGLE_ANALYTICS_ON === "true",
    hitBetBetTypesUrl: config?.REACT_APP_HITBET_BET_TYPES_URL,
    hitBetBettingRulesUrl: config?.REACT_APP_HITBET_BETTING_RULES_URL,
    hitBetCasinoRulesUrl: config?.REACT_APP_HITBET_CASINO_RULES_URL,
    ezBetBetslipModeOn: config?.REACT_APP_EZBET_BETSLIP_MODE_ON === "true",
    hitBetCasinoUrl: config?.REACT_APP_HITBET_CASINO_URL,
    ezBetSwipeDebugOn: config?.REACT_APP_EZBET_SWIPE_DEBUG_ON === "false",
    hitBetContactUsUrl: config?.REACT_APP_HITBET_CONTACT_US_URL,
    hitBetDashboardUrl: config?.REACT_APP_HITBET_DASHBOARD_URL,
    hitBetDepositorsUrl: config?.REACT_APP_HITBET_DEPOSITORS_URL,
    hitBetGameUrl: config?.REACT_APP_HITBET_GAME_URL,
    hitBetGraphGameUrl: config?.REACT_APP_HITBET_GRAPH_GAME_URL,
    hitBetLiveCasinoUrl: config?.REACT_APP_HITBET_LIVE_CASINO_URL,
    hitBetLiveChatUrl: config?.REACT_APP_HITBET_LIVE_CHAT_URL,
    hitBetLiveResultsUrl: config?.REACT_APP_HITBET_LIVE_RESULTS_URL,
    initialized: !!(
      config?.REACT_APP_DESKTOP_ORIGIN_ID ||
      config?.REACT_APP_MOBILE_ORIGIN_ID ||
      config?.REACT_APP_SLIPSTREAM_ORIGIN_ID ||
      config?.REACT_APP_BETPOINT_ORIGIN_ID
    ),
    hitBetPromotionsUrl: config?.REACT_APP_HITBET_PROMOTIONS_URL,
    mobileOriginId: config?.REACT_APP_MOBILE_ORIGIN_ID,
    hitBetSettingsUrl: config?.REACT_APP_HITBET_SETTINGS_URL,
    slipstreamOriginId: config?.REACT_APP_DESKTOP_ORIGIN_ID,
    hitBetStatisticsUrl: config?.REACT_APP_HITBET_STATISTICS_URL,
    slipstreamOn: config?.REACT_APP_SLIPSTREAM_ON === "true",
    hitBetTermsAndConditionsUrl: config?.REACT_APP_HITBET_TERMS_AND_CONDITIONS_URL,
    simulateXForwardedFor: config?.REACT_APP_SIMULATE_X_FORWARDED_FOR === "true",
    specificLoadingLogoConfig: config?.REACT_APP_SPECIFIC_LOADING_LOGO_CONFIG,
    twelveBetSettingModeOn: config?.REACT_APP_12BET_SETTING_MODE_ON === "true",
  };

  return variables;
};

export function useConfigIsInitialized() {
  const config = useConfig();

  return config?.initialized;
}

export function useDesktopOriginId() {
  const config = useConfig();

  return config?.desktopOriginId;
}

export function useMobileOriginId() {
  const config = useConfig();

  return config?.mobileOriginId;
}

export function useBetPointOriginId() {
  const config = useConfig();

  return config?.betpointOriginId;
}

export function useSlipstreamOriginId() {
  const config = useConfig();

  return config?.slipstreamOriginId;
}

export function useDesktopLineId() {
  const lineId = 2; // static at the moment

  return lineId;
}

export function useMobileLineId() {
  const lineId = 2; // static at the moment

  return lineId;
}

export function useBetPointLineId() {
  const lineId = 2; // static at the moment

  return lineId;
}

export function useSlipstreamLineId() {
  const lineId = 2; // static at the moment

  return lineId;
}

export function useSlipstreamOn() {
  const config = useConfig();

  return !!config?.slipstreamOn;
}

export function useBetPointOn() {
  const config = useConfig();

  return !!config?.betpointOn;
}

export function useThirdPartyBalance(dispatch) {
  const loggedIn = useSelector(getAuthLoggedIn);
  const accountId = useSelector(getAuthAccountId);
  const authIFrameQueryParamsProcessed = useSelector(getAuthIFrameQueryParamsProcessed);
  const cmsConfigBrandDetails = useSelector(getCmsConfigBrandDetails);

  useEffect(() => {
    if (
      authIFrameQueryParamsProcessed &&
      cmsConfigBrandDetails &&
      loggedIn &&
      cmsConfigBrandDetails.data.singleWalletMode &&
      !process.env.REACT_APP_SLIPSTREAM_ON
    ) {
      dispatch(loadSingleWalletBalance({ accountId }));

      const interval = setInterval(() => {
        dispatch(loadSingleWalletBalance({ accountId }));
      }, 5000);

      return () => {
        // unsubscribe
        clearInterval(interval);
      };
    }

    return undefined;
  }, [dispatch, authIFrameQueryParamsProcessed, cmsConfigBrandDetails, accountId, loggedIn]);
}
