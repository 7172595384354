import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import originalAxios from "axios";

import { isNotEmpty } from "../../utils/lodash";
import { createDataHash } from "../../utils/sports-data/sportsDashHash";
import createAxiosInstance from "../async/axios";
import { getRequestParams } from "../async/get-fetch-params";
import { getAuthPriceFormat } from "../reselect/auth-selector";

export const getInitialState = (couponData = {}) => ({
  activeSearchKeyword: null,
  couponData, // keep a map of all data per search code (events and paths)
  couponError: {},
  couponFromTimestamp: {}, // keep a map of all timestamps per search code (events and paths)
  couponLoading: {},
});

// Helper function to clean sports data
function cleanSportsData(sports) {
  return sports.filter((sport) => {
    sport.categories = sport.categories.filter((category) => {
      category.tournaments = category.tournaments.filter((tournament) => {
        tournament.events = tournament.events.filter((event) => event.cStatus !== "END_OF_EVENT");

        return tournament.events.length > 0;
      });

      return category.tournaments.length > 0;
    });

    return sport.categories.length > 0;
  });
}

function mergeMarketsData(oldMarkets, newMarkets) {
  const mergedMarkets = [...oldMarkets];

  newMarkets.forEach((newMarket) => {
    const index = mergedMarkets.findIndex((oldMarket) => oldMarket.id === newMarket.id);
    if (index !== -1) {
      mergedMarkets[index] = {
        ...mergedMarkets[index],
        ...newMarket,
        outcomes: newMarket.outcomes, // Overriding outcomes as requested
      };
    } else {
      mergedMarkets.push(newMarket);
    }
  });

  return mergedMarkets;
}

function mergeEventsData(oldEvents, newEvents) {
  const mergedEvents = [...oldEvents];

  newEvents.forEach((newEvent) => {
    const index = mergedEvents.findIndex((oldEvent) => oldEvent.id === newEvent.id);
    if (index !== -1) {
      mergedEvents[index] = {
        ...mergedEvents[index],
        ...newEvent,
        markets: mergeMarketsData(mergedEvents[index].markets, newEvent.markets),
      };
    } else {
      mergedEvents.push(newEvent);
    }
  });

  return mergedEvents;
}

function mergeTournamentsData(oldTournaments, newTournaments) {
  const mergedTournaments = [...oldTournaments];

  newTournaments.forEach((newTournament) => {
    const index = mergedTournaments.findIndex((oldTournament) => oldTournament.id === newTournament.id);
    if (index !== -1) {
      mergedTournaments[index] = {
        ...mergedTournaments[index],
        ...newTournament,
        events: mergeEventsData(mergedTournaments[index].events, newTournament.events),
      };
    } else {
      mergedTournaments.push(newTournament);
    }
  });

  return mergedTournaments;
}

function mergeCategoriesData(oldCategories, newCategories) {
  const mergedCategories = [...oldCategories];

  newCategories.forEach((newCategory) => {
    const index = mergedCategories.findIndex((oldCategory) => oldCategory.id === newCategory.id);
    if (index !== -1) {
      mergedCategories[index] = {
        ...mergedCategories[index],
        ...newCategory,
        tournaments: mergeTournamentsData(mergedCategories[index].tournaments, newCategory.tournaments),
      };
    } else {
      mergedCategories.push(newCategory);
    }
  });

  return mergedCategories;
}

function mergeSportsData(oldData, newData) {
  const mergedData = [...oldData];

  newData.forEach((newSport) => {
    const index = mergedData.findIndex((oldSport) => oldSport.sportCode === newSport.sportCode);
    if (index !== -1) {
      mergedData[index] = {
        ...mergedData[index],
        ...newSport,
        categories: mergeCategoriesData(mergedData[index].categories, newSport.categories),
      };
    } else {
      mergedData.push(newSport);
    }
  });

  // make sure they are sorted
  mergedData.sort((a, b) => a.pos - b.pos);

  return mergedData;
}

function prepareParams(originId, lineId, data) {
  const params = new URLSearchParams({
    lineId,
    originId,
  });

  const booleanParams = [
    "allMarkets", // Return all markets, not just the primary one
    "allowMultiMarket", // For multi-market market types, return all available, not just the balanced one
    "betBuilder", // Bet builder mode
    "compactSpread", // return spreads in compact format
    "featured", // Return featured events only
    "icon", // Include team / player icons, if any
    "nextEvents", // return next events (chronologically sorted)
    "shortNames", // Return short names (Home / Away)
  ];

  const stringParams = [
    "betBuilderSelections", // Include bet builder selections already in the betslip
    "eventType", // Search by event type (GAME, RANK, ALL)
    "fromDate", // Search by date (applicable to prematch events only)
    "keywordSearch", // Search by keyword
    "marketTypeGroups", // Filter by specific market type groups. It returns the  most balanced market for each category, unless the allowMultiMarket flag is on
    "matchState", // Search by match state (PREMATCH, LIVE, ALL)
    "opcCriteria", // Return market groupings by OPC criteria
    "toDate", // Search by date (applicable to prematch events only)
    "virtualStatus", // Search by virtual state (VIRTUAL, NON_VIRTUAL, ALL)
  ];

  const numericParams = ["count"];

  // We start by adding the mandatory parameters (items)
  const items = data.requestCodeCollection.toString();
  params.append("items", items);

  // then we iterate by the categories, and identify what can be mapped to the URL

  booleanParams.forEach((param) => {
    if (data[param]) params.append(param, data[param]);
  });

  stringParams.forEach((param) => {
    if (data[param]) params.append(param, data[param]);
  });

  numericParams.forEach((param) => {
    if (data[param]) params.append(param, data[param]);
  });

  // we treat the timestamp as a special case
  if (!data.betBuilder) {
    if (data.fromTimestamp) params.append("fromTimestamp", data.fromTimestamp);
  }

  return params.toString();
}

const couponCancelToken = {};

export const loadCouponData = createAsyncThunk("sportsData/loadCouponData", async (data, thunkAPI) => {
  try {
    const { authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());
    const priceFormat = getAuthPriceFormat(thunkAPI.getState());

    const { betBuilderSelections: _, ...dataWithoutBetBuilder } = data;
    const codesForTracking = createDataHash(dataWithoutBetBuilder);

    let thisCancelToken = null;
    // Check if there are any previous pending requests

    if (couponCancelToken[codesForTracking]) {
      // cancel the previous operation...
      couponCancelToken[codesForTracking].cancel("Operation canceled due to new request.");
    }
    // Save the cancel token for the current request
    couponCancelToken[codesForTracking] = originalAxios.CancelToken.source();
    thisCancelToken = couponCancelToken[codesForTracking];

    const fromTimestamp = thunkAPI.getState().sportData.couponFromTimestamp[codesForTracking];
    const queryString = prepareParams(originId, lineId, {
      ...data,
      fromTimestamp,
    });

    const axios = createAxiosInstance(thunkAPI.dispatch, { authToken, language });
    if (priceFormat) {
      axios.defaults.headers["x-priceformat"] = priceFormat;
    }

    const result = await axios.get(`/player/sd?${queryString}`, { cancelToken: thisCancelToken.token }); // Pass the cancel token to the current request

    delete couponCancelToken[codesForTracking];

    return {
      couponData: result.data,
      couponFromTimestamp: result.data.toTimestamp,
      locked: (!!data.virtual && result.data.virtualLocked) || (!data.virtual && result.data.sportsLocked),
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to load coupon data", // serializable (err.response.data)
      name: "Load Coupon Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

const sportDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(loadCouponData.pending, (state, action) => {
        const codesForTracking = createDataHash(action.meta.arg);
        state.couponLoading[codesForTracking] = true;
        state.couponError[codesForTracking] = null;
      })
      .addCase(loadCouponData.rejected, (state, action) => {
        const codesForTracking = createDataHash(action.meta.arg);
        state.couponError[codesForTracking] = action.error;
        state.couponLoading[codesForTracking] = false;
      })
      .addCase(loadCouponData.fulfilled, (state, action) => {
        const { betBuilderSelections: _, ...dataWithoutBetBuilder } = action.meta.arg;
        const codesForTracking = createDataHash(dataWithoutBetBuilder);

        if (action.payload.locked) {
          // if we are under lockdown, clear it all out
          delete state.couponData[codesForTracking];
          delete state.couponFromTimestamp[codesForTracking];
          delete state.couponError[codesForTracking];
          delete state.couponLoading[codesForTracking];

          return;
        }

        state.couponData[codesForTracking] = mergeSportsData(
          state.couponData[codesForTracking] || [],
          action.payload.couponData.sports,
        );
        state.couponFromTimestamp[codesForTracking] = action.payload.couponFromTimestamp;
        state.couponLoading[codesForTracking] = false;
      });
  },
  initialState: getInitialState(),
  name: "sportData",
  reducers: {
    clearAllCouponData: (state) => {
      state.couponData = {};
      state.couponError = {};
      state.couponFromTimestamp = {};
      state.couponLoading = {};
    },
    clearCouponData: (state, action) => {
      const code = action.payload.code;

      delete state.couponData[code];
      delete state.couponFromTimestamp[code];
      delete state.couponError[code];
      delete state.couponLoading[code];
    },
    consumeLiveCouponData: (state, action) => {
      const code = action.payload.subscription;
      const data = action.payload.data;

      if (false) {
        // if error
        // if we are under lockdown, clear it all out
        delete state.couponData[code];
        delete state.couponFromTimestamp[code];
        delete state.couponError[code];
        delete state.couponLoading[code];
      } else {
        state.couponData[code] = mergeSportsData(state.couponData[code] || [], data.sports);
        state.couponFromTimestamp[code] = action.payload.couponFromTimestamp;
      }
    },
    evictEndedMatches: (state, action) => {
      Object.keys(state.couponData).forEach((code) => {
        if (isNotEmpty(state.couponData[code])) {
          state.couponData[code] = cleanSportsData(state.couponData[code]);
        }
      });
    },
  },
});

const { actions, reducer } = sportDataSlice;

export const { clearAllCouponData, clearCouponData, consumeLiveCouponData, evictEndedMatches } = sportDataSlice.actions;

export default reducer;
