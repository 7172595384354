import { AbstractPriceFormat } from "./abstractPriceFormat";

export class EuroPriceFormat extends AbstractPriceFormat {
  constructor() {
    super();
    this.MIN_DECIMAL_PLACES = 2;
    this.MAX_DECIMAL_PLACES = 3;
    this.CHAR_NOT_ALLOWED = "Character Not Allowed";
    this.INVALID_PRICE_VALUE = "Invalid Price";
  }

  format(price) {
    if (price === 0) {
      return this.NO_PRICE;
    }

    return this.convertDecimalToEuro(price);
  }

  parse(price) {
    if (price === null || price === this.NO_PRICE) {
      return 0.0;
    }

    const decimalSep = this.getDecimalSeparator();

    if (price.includes("/")) {
      throw new Error(`${this.CHAR_NOT_ALLOWED}: /`);
    }
    if (price.includes("+")) {
      throw new Error(`${this.CHAR_NOT_ALLOWED}: +`);
    }
    if (price.includes("-")) {
      throw new Error(`${this.CHAR_NOT_ALLOWED}: -`);
    }

    if (!this.isNumeric(price, decimalSep)) {
      throw new Error(this.INVALID_PRICE_VALUE);
    }

    // Replace '.' with the decimal separator (which might be ',')
    price = price.replace(".", decimalSep);

    return this.convertEuroToDecimal(price);
  }

  convertDecimalToEuro(price) {
    const formatted = this.getDecimalFormat().format(price);

    return this.removeUnwantedZeros(formatted);
  }

  convertEuroToDecimal(price) {
    const parsedNumber = this.getDecimalFormat().parse(price);
    if (Number.isNaN(parsedNumber)) {
      throw new Error(`Unparseable number: "${price}"`);
    }

    return parsedNumber;
  }

  removeUnwantedZeros(price) {
    const decimalSeparator = this.getDecimalSeparator();
    const parts = price.split(decimalSeparator);
    if (parts.length > 1) {
      const decimals = parts[1].replace(/0+$/, "");

      return decimals.length > 0 ? `${parts[0]}${decimalSeparator}${decimals}` : parts[0];
    }

    return price;
  }

  getDecimalFormat() {
    return {
      format: (number) =>
        number.toLocaleString("en-GB", {
          maximumFractionDigits: this.MAX_DECIMAL_PLACES,
          minimumFractionDigits: this.MIN_DECIMAL_PLACES,
          useGrouping: false,
        }),
      parse: (string) => parseFloat(string.replace(",", ".")),
    };
  }

  getDecimalSeparator() {
    return ","; // Assuming Euro format uses comma as decimal separator
  }

  isNumeric(strTest, decimalSep) {
    const regex = new RegExp(`^[+-]?(\\d+[${decimalSep}])?\\d+([${decimalSep}]\\d*)?$`);

    return regex.test(strTest.trim());
  }
}
