import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { getAuthLanguage } from "../redux/reselect/auth-selector";
import { getSports } from "../redux/slices/sportSlice";
import {
  useBetPointLineId,
  useBetPointOriginId,
  useDesktopLineId,
  useDesktopOriginId,
  useMobileLineId,
  useMobileOriginId,
  useSlipstreamLineId,
  useSlipstreamOriginId,
} from "utils/AppUtils";

const useOriginId = (isSlipstream, isBetPoint, isMobileDevice) => {
  const slipstreamOriginId = useSlipstreamOriginId();
  const betPointOriginId = useBetPointOriginId();
  const mobileOriginId = useMobileOriginId();
  const desktopOriginId = useDesktopOriginId();

  if (isSlipstream) {
    return slipstreamOriginId;
  }

  if (isBetPoint) {
    return betPointOriginId;
  }

  if (isMobileDevice) {
    return mobileOriginId;
  }

  return desktopOriginId;
};

const useLineId = (isSlipstream, isBetPoint, isMobileDevice) => {
  const slipstreamLineId = useSlipstreamLineId();
  const betPointLineId = useBetPointLineId();
  const mobileLineId = useMobileLineId();
  const desktopLineId = useDesktopLineId();

  if (isSlipstream) {
    return slipstreamLineId;
  }

  if (isBetPoint) {
    return betPointLineId;
  }

  if (isMobileDevice) {
    return mobileLineId;
  }

  return desktopLineId;
};

export function useSports(isBetPoint, isMobileDevice, isSlipstream, dispatch) {
  const language = useSelector(getAuthLanguage);

  const originId = useOriginId(isSlipstream, isBetPoint, isMobileDevice);
  const lineId = useLineId(isSlipstream, isBetPoint, isMobileDevice);

  useEffect(() => {
    const source = axios.CancelToken.source();
    // if no config, or app type changed...

    dispatch(
      getSports({
        cancelToken: source.token,
        lineId,
        originId,
      }),
    );

    return () => {
      source.cancel();
    };
  }, [isMobileDevice, dispatch, language]);
}
