const GenericMaintenancePage = () => (
  <article
    style={{
      backgroundColor: "white",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "650px",
      textAlign: "center",
    }}
  >
    <h1 style={{ fontSize: "3em" }}>We&rsquo;ll be back soon!</h1>
    <div style={{ fontSize: "1.3em" }}>
      <p style={{ marginBlockStart: "2em" }}>
        Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. We&rsquo;ll be back
        online shortly!
      </p>

      <p style={{ marginBlockStart: "2em" }}>
        &#52509;&#49845;&#54616;&#45716;&#48152; &#51109;&#51008; &#51228;&#44536; &#51473;&#45768;&#45796;. &#44256;
        &#45796;&#44480; &#50752;&#46972;&#51008; &#46020;&#44221;&#50640; &#50696;&#51204;&#51008;
        &#50640;&#49436;&#45716;&#45796;!
      </p>

      <p style={{ marginBlockStart: "2em" }}>
        &#25226;&#24773;&#32473;&#24744;&#24102;&#26469;&#19981;&#20415;&#65292;&#25105;&#20204;&#30476;&#21069;&#27491;&#22312;&#25191;&#34892;&#19968;&#20123;&#32500;&#25252;&#24037;&#20316;&#12290;&#25105;&#20204;&#23558;&#24456;&#24555;&#24687;&#22797;&#22312;&#32447;&#65281;
      </p>

      <p style={{ marginBlockStart: "2em" }}>
        &#161;Lo sentimos por las molestias, pero estamos realizando algunos trabajos de mantenimiento en este momento.
        &#161;Volveremos en l&#237;nea en breve!
      </p>

      <p style={{ marginBlockStart: "2em" }}>
        &#2325;&#2358;&#2381;&#2350;&#2366; &#2325;&#2352;&#2375;&#2344;&#44; &#2354;&#2375;&#2325;&#2381;&#2352;
        &#2361;&#2367;&#2344; &#2360;&#2350;&#2375;&#2358; &#2325;&#2361; &#2352;&#2361;&#2368;&#2306;&#46;
        &#2361;&#2376;&#2306; &#2332;&#2354;&#2381;&#2340;&#2367; &#2361;&#2367;&#2340;
        &#2354;&#2375;&#2332;&#2306;&#2327;&#2306;&#33;
      </p>

      <p style={{ marginBlockStart: "2em" }}>
        &#3588;&#3619;&#3634;&#3618;&#3611;&#3619;&#3633;&#3585;&#3619;&#3633;&#3610;
        &#3634;&#3619;&#3603;&#3634;&#3623;&#3633;&#3609;&#3604;&#3640;&#3603;
        &#3585;&#3633;&#3610;&#3627;&#3609;&#3637;&#3657;&#3618;&#3609;&#3619;&#3640;&#3651;&#3610;&#3591;&#3634;&#3585;&#3619;&#3657;&#3629;&#3591;&#46;
        &#3619;&#3657;&#3634;&#3609;&#3585;&#3656;&#3629;&#3591;&#3588;&#3635;&#3619;&#3641;&#3657;&#3619;&#3633;&#3610;&#3621;&#3621;&#3591;&#3648;&#3585;&#3637;&#3656;&#3618;&#3651;&#3594;&#3657;&#33;
      </p>

      <p style={{ marginBlockStart: "2em" }}>
        Xin l&#7885;i v&#236; s&#7921; b&#7845;t ti&#7879;n n&#224;y nh&#432;ng ch&#250;ng t&#244;i &#273;ang ti&#7871;n
        h&#224;nh b&#7843;o d&#432;&#7907;ng v&#224;o l&#250;c n&#224;y&#46; Ch&#250;ng t&#244;i s&#7869; tr&#7903;
        l&#7841;i tr&#7921;c tuy&#7871;n trong th&#7901;i gian ng&#7855;n&#33;
      </p>

      <p style={{ marginBlockStart: "2em" }}>
        Maaf atas kesulitan yang berlaku tetapi kami sedang melakukan beberapa penyelenggaraan pada masa ini&#46; Kami
        akan kembali dalam talian tidak lama lagi&#33;
      </p>

      <p style={{ marginBlockStart: "2em" }}>
        Maaf atas ketidaknyamanannya&#44; tetapi kami sedang melakukan pemeliharaan saat ini&#46; Kami akan kembali
        online dalam waktu singkat&#33;
      </p>
    </div>
  </article>
);

const MaintenancePage = () => <GenericMaintenancePage />;

export default MaintenancePage;
