import axios from "axios";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { sanitiseLanguage } from "../utils/language-utils";

import { getAuthLanguage } from "../redux/reselect/auth-selector";
import { getCmsConfigError, getCmsConfigErrorCode, getCmsConfigSelector } from "../redux/reselect/cms-selector";
import { getCmsConfig } from "../redux/slices/cmsSlice";
import i18n from "services/i18n";

const getISOLanguage = (lang) => {
  if (lang === "in") return "id"; // cover for the usual mess with Indonesian

  return lang;
};

export function useCmsConfig(dispatch, originId, lineId) {
  const cmsConfig = useSelector(getCmsConfigSelector);
  const cmsConfigError = useSelector(getCmsConfigError);
  const cmsConfigErrorCode = useSelector(getCmsConfigErrorCode);
  const authLanguage = useSelector(getAuthLanguage);

  const { search } = useLocation();
  const query = new URLSearchParams(search); // this might come from query params - make sure to avoid race conditions...
  const rawLanguage = query.get("lang") || query.get("language");
  const queryLanguage = rawLanguage ? sanitiseLanguage(rawLanguage) : undefined; // we do this to avoid race conditions

  const language = queryLanguage || authLanguage;

  useEffect(() => {
    if (originId && lineId) {
      // eslint-disable-next-line no-inner-declarations
      async function fetchGetCmsConfig({ lineId, originId, source }) {
        await dispatch(
          getCmsConfig({
            cancelToken: source.token,
            lineId,
            originId,
            overriddenLanguage: language,
          }),
        );
      }

      const source = axios.CancelToken.source();
      // if no config, or app type changed...

      fetchGetCmsConfig({ lineId, originId, source });

      if (i18n.language !== getISOLanguage(language)) {
        const isoLanguage = getISOLanguage(language);
        if (isoLanguage) {
          i18n.changeLanguage(isoLanguage); // For React static text translation purposes
          dayjs.locale(isoLanguage);
        }
      }

      return () => {
        source.cancel();
      };
    }

    return undefined;
  }, [dispatch, originId, lineId, language]); // if the language or device changes, get all CMS data

  return { cmsConfig, cmsConfigError, cmsConfigErrorCode };
}
