import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import createAxiosInstance from "../async/axios";
import { getRequestParams } from "../async/get-fetch-params";

export const getBetTypeInitialState = (betTypes = null, language = null) => ({
  betTypes,
  error: null,
  language,
  loading: false,
});

export const getBetTypes = createAsyncThunk("betType/betTypes", async (data, thunkAPI) => {
  try {
    const { authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());
    const axios = createAxiosInstance(thunkAPI.dispatch, {
      authToken,
      language,
    });

    const result = await axios.get(`/player/bettypes?originId=${originId}&lineId=${lineId}`);

    return {
      betTypes: result.data.reduce((hash, obj) => {
        hash[obj.id] = obj;

        return hash;
      }, {}),
      language,
    };
  } catch (err) {
    const customError = {
      message: err.response?.headers["x-information"] || "Unable to obtain bet type details", // serializable (err.response.data)
      name: "Bet type list Fetch Error",
      status: err.response?.statusText,
    };
    throw customError;
  }
});

const betTypeSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getBetTypes.pending, (state) => {
        state.betTypes = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(getBetTypes.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(getBetTypes.fulfilled, (state, action) => {
        state.betTypes = action.payload.betTypes;
        state.language = action.payload.language;
        state.loading = false;
        state.error = null;
      });
  },
  initialState: getBetTypeInitialState(),
  name: "betType",
  reducers: {},
});
const { reducer } = betTypeSlice;
export default reducer;
