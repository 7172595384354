import { createContext, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { consumeMessage, consumePingStatus } from "../redux/slices/liveSlice";
import { consumeLiveCouponData } from "../redux/slices/sportDataSlice";

const WS_STATUS_CONNECTING = 0;
const WS_STATUS_READY = 1;
const WS_STATUS_CLOSING = 2;
const WS_STATUS_CLOSED = 3;

const wsEndpoint = `${window.location.origin}/ws/live`.replace("https", "wss").replace("http", "ws");

const WebSocketContext = createContext(null);

const WebSocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [ws, setWs] = useState(null);
  const [readyState, setReadyState] = useState(WS_STATUS_CLOSED);

  const createWebSocket = useCallback(() => {
    const newWs = new WebSocket(wsEndpoint);

    newWs.onopen = () => {
      console.log("WebSocket - Connection established!");
      setReadyState(WS_STATUS_READY);
    };

    newWs.onmessage = (message) => {
      const payload = JSON.parse(message.data);
      if (payload.subscription === "ping") {
        dispatch(consumePingStatus({ locked: payload.data?.locked }));
      } else if (payload.data?.toTimestamp) {
        dispatch(consumeLiveCouponData({ ...payload }));
      } else {
        dispatch(consumeMessage(payload.subscription, payload.data));
      }
    };

    newWs.onclose = (e) => {
      console.log(`WebSocket closed with code ${e.code}`);
      setReadyState(WS_STATUS_CLOSED);
    };

    newWs.onerror = (err) => {
      console.error("WebSocket encountered error: ", err.message);
      newWs.close();
    };

    setWs(newWs);
  }, [dispatch]);

  useEffect(() => {
    createWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [createWebSocket]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (ws && ws.readyState === WS_STATUS_CLOSED) {
        console.log("WebSocket: Reconnecting...");
        createWebSocket();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [ws, createWebSocket]);

  useEffect(() => {
    if (ws) {
      const pingIntervalId = setInterval(() => {
        if (ws.readyState === WS_STATUS_READY) {
          try {
            ws.send(JSON.stringify({ subscription: "ping" }));
          } catch (e) {
            console.log("Error sending ping:", e);
          }
        }
      }, 5000);

      return () => clearInterval(pingIntervalId);
    }
  }, [ws]);

  const contextValue = {
    createWebSocket,
    readyState,
    ws,
  };

  return <WebSocketContext.Provider value={contextValue}>{children}</WebSocketContext.Provider>;
};

export { WebSocketContext, WebSocketProvider };
