import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createAxiosInstance from "../async/axios";
import { getRequestParams } from "../async/get-fetch-params";
import originalAxios from "axios";
import { getAuthLoggedIn } from "../reselect/auth-selector";

export const getInitialState = () => ({
  error: null,
  loading: false,
  recommendedEvents: [],
  recommendedBetslips: [],
  popularBetslips: [],
  recommendedBetBuilders: [],
  similarEvents: [],
  searchResults: [],
});

const vaixCancelToken = {};

const createVaixThunk = (name, endpoint, prepareParams = (params) => params) => {
  return createAsyncThunk(`vaix/${name}`, async (params, thunkAPI) => {
    try {
      const { accountId, authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());

      if (vaixCancelToken[name]) {
        vaixCancelToken[name].cancel("Operation canceled due to new request.");
      }
      vaixCancelToken[name] = originalAxios.CancelToken.source();

      const axios = createAxiosInstance(thunkAPI.dispatch, { authToken, language });

      const result = await axios.get(
        `/player/${accountId ? `acc/${accountId}/` : ""}vaix?${new URLSearchParams(
          prepareParams({
            originId,
            lineId,
            vaix: name,
            ...params,
          }),
        )}`,
        {
          cancelToken: vaixCancelToken[name].token,
        },
      );

      return result.data;
    } catch (err) {
      const customError = {
        message: err.response?.headers["x-information"] || `Unable to fetch ${name}`,
        name: `${name} Error`,
        status: err.response?.statusText,
      };
      throw customError;
    }
  });
};

export const getRecommendedEvents = createVaixThunk("RECOMMENDED_EVENTS", (params) => ({
  ...params,
  live: params.live ? 1 : undefined,
}));

export const getRecommendedBetslips = createVaixThunk("RECOMMENDED_BETSLIPS");

export const getPopularBetslips = createVaixThunk("POPULAR_BETSLIPS");

export const getRecommendedBetBuilders = createVaixThunk("RECOMMENDED_BET_BUILDERS");

export const getSimilarEvents = createVaixThunk("EVENTS_SIMILAR", (params) => ({
  ...params,
  live: params.live ? 1 : undefined,
  eventIds: params.eventIds.join(","),
}));

export const searchEvents = createVaixThunk("SEARCH", (params) => ({ ...params, live: params.live ? 1 : undefined }));

// New tracking thunk creator
const createTrackingThunk = (name) => {
  return createAsyncThunk(`vaix/track/${name}`, async (params, thunkAPI) => {
    try {
      const { accountId, authToken, language, lineId, originId } = getRequestParams(thunkAPI.getState());
      const isLoggedIn = getAuthLoggedIn(thunkAPI.getState());
      if (!isLoggedIn) return;

      const axios = createAxiosInstance(thunkAPI.dispatch, { authToken, language });

      const result = await axios.post(
        `/player/${accountId ? `acc/${accountId}/` : ""}vaix/track?${new URLSearchParams({
          originId,
          lineId,
        })}`,
        params,
      );

      return result.data;
    } catch (err) {
      const customError = {
        message: err.response?.headers["x-information"] || `Unable to track ${name}`,
        name: `${name} Tracking Error`,
        status: err.response?.statusText,
      };
      throw customError;
    }
  });
};

// Create tracking thunks for each event type
export const trackEventClick = createTrackingThunk("EVENT_CLICK");
export const trackOutcomeClick = createTrackingThunk("OUTCOME_CLICK");
export const trackCombinationsClick = createTrackingThunk("COMBINATIONS_CLICK");

// Helper functions to format tracking data
export const createEventClickPayload = ({ eventId, page = "/home", location = "" }) => ({
  eventId,
  page,
  location,
  vaixEventType: "CLICK_EVENT",
});

export const createOutcomeClickPayload = ({ outcomeId, eventId, page = "/home", location = "" }) => ({
  outcomeId,
  eventId,
  page,
  location,
  vaixEventType: "CLICK_OUTCOME",
});

export const createCombinationsClickPayload = ({ selections, page = "/home", location = "", position = 0 }) => ({
  page,
  location,
  position,
  vaixEventType: "CLICK_COMBINATIONS",
  selections: selections.map(({ outcomeId, eventId }) => ({
    outcomeId,
    eventId,
  })),
});

const vaixSlice = createSlice({
  name: "vaix",
  initialState: getInitialState(),
  reducers: {
    clearVaixData: (state) => {
      return getInitialState();
    },
    clearRecommendedEvents: (state) => {
      state.recommendedEvents = [];
    },
    clearRecommendedBetslips: (state) => {
      state.recommendedBetslips = [];
    },
    clearPopularBetslips: (state) => {
      state.popularBetslips = [];
    },
    clearRecommendedBetBuilders: (state) => {
      state.recommendedBetBuilders = [];
    },
    clearSimilarEvents: (state) => {
      state.similarEvents = [];
    },
    clearSearchResults: (state) => {
      state.searchResults = [];
    },
  },
  extraReducers: (builder) => {
    const addVaixCases = (thunk, stateProp) => {
      builder
        .addCase(thunk.pending, (state) => {
          state.error = null;
          state.loading = true;
        })
        .addCase(thunk.rejected, (state, action) => {
          state.error = action.error.message;
          state.loading = false;
        })
        .addCase(thunk.fulfilled, (state, action) => {
          state.error = null;
          state.loading = false;
          state[stateProp] = action.payload;
        });
    };

    addVaixCases(getRecommendedEvents, "recommendedEvents");
    addVaixCases(getRecommendedBetslips, "recommendedBetslips");
    addVaixCases(getPopularBetslips, "popularBetslips");
    addVaixCases(getRecommendedBetBuilders, "recommendedBetBuilders");
    addVaixCases(getSimilarEvents, "similarEvents");
    addVaixCases(searchEvents, "searchResults");
  },
});

export const {
  clearVaixData,
  clearRecommendedEvents,
  clearRecommendedBetslips,
  clearPopularBetslips,
  clearRecommendedBetBuilders,
  clearSimilarEvents,
  clearSearchResults,
} = vaixSlice.actions;

export default vaixSlice.reducer;
